import { useEffect, useMemo, useRef } from 'react';

const SCRIPT_SRC_BASE = 'https://app.termly.io';

export default function TermlyCMP({
  autoBlock,
  masterConsentsOrigin,
  websiteUUID,
}: {
  autoBlock?: boolean;
  masterConsentsOrigin?: string;
  websiteUUID: string;
}) {
  const scriptSrc = useMemo(() => {
    const src = new URL(SCRIPT_SRC_BASE);
    src.pathname = `/resource-blocker/${websiteUUID}`;
    if (autoBlock) {
      src.searchParams.set('autoBlock', 'on');
    }
    if (masterConsentsOrigin) {
      src.searchParams.set('masterConsentsOrigin', masterConsentsOrigin);
    }
    return src.toString();
  }, [autoBlock, masterConsentsOrigin, websiteUUID]);

  const isScriptAdded = useRef(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    if (isScriptAdded.current || document.getElementById('termly-consent'))
      return;
    const script = document.createElement('script');
    script.src = scriptSrc;
    script.id = 'termly-consent';
    document.head.appendChild(script);
    isScriptAdded.current = true;
  }, [scriptSrc]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
    (window as any)?.Termly?.initialize();
  }, []);

  return null;
}
