import { LinkTreeMapping } from './link-tree';
import { SidebarFragment, SidebarHeaderItem } from './Sidebar';

export const expressiveBidding: LinkTreeMapping = {
  children: [
    {
      component: SidebarHeaderItem,
      heading: 'Expressive Bidding',
    },
    {
      heading: 'Introduction',
      slug: '/docs/expressive/index.mdx',
    },
    {
      heading: 'Getting Started',
      slug: '/docs/expressive/start-here.mdx',
    },
    {
      heading: 'Expressive Bidding Guide',
      slug: '/docs/expressive/bidding-guide.mdx',
    },
    {
      heading: 'Quickstart Examples',
      children: [
        {
          heading: 'Dynamic Peg (Not Live)',
          slug: '/docs/tutorials/peg.mdx',
        },
        {
          heading: 'Pairs (Live)',
          slug: '/docs/tutorials/pairs.mdx',
        },
        {
          heading: 'Price-Quantity Curves (Not Live)',
          slug: '/docs/tutorials/price-curve.mdx',
        },
      ],
    },
    {
      heading: 'Runnable Templates',
      children: [
        {
          heading: 'About These Templates',
          slug: '/docs/templates/index.mdx',
        },
        {
          heading: 'Single Stock Execution (Not Live)',
          slug: '/docs/templates/single-stock.mdx',
        },
        {
          heading: 'Price-Quantity Indifference Curves (Not Live)',
          slug: '/docs/templates/indifference-curves.mdx',
        },
        {
          heading: 'Hedging (Not Live)',
          slug: '/docs/templates/hedging.mdx',
        },
        {
          heading: 'Pairs (Live)',
          slug: '/docs/templates/pairs.mdx',
        },
        {
          heading: 'ETFs (Not Live)',
          slug: '/docs/templates/etf.mdx',
        },
        {
          heading: 'Portfolios (Not Live)',
          slug: '/docs/templates/portfolios.mdx',
        },
      ],
    },
    {
      heading: 'Developer Reference',
      slug: '/docs/expressive/reference.mdx',
    },
    {
      heading: 'Expressive Bidding FAQ',
      slug: '/docs/expressive/faq.mdx',
    },
  ],
};

export const fixIntegration: LinkTreeMapping = {
  children: [
    { component: SidebarHeaderItem, heading: 'FIX Integration' },
    {
      heading: 'FIX Spec',
      slug: '/docs/fix/fix-42/index.mdx',
    },
    {
      heading: 'Symbology',
      slug: '/docs/symbology.mdx',
    },
    {
      heading: 'FIX Primer (Optional)',
      slug: '/docs/fix/primer.mdx',
    },
  ],
};

export const marketStructure: LinkTreeMapping = {
  children: [
    { component: SidebarHeaderItem, heading: 'Market Structure' },
    {
      heading: 'Clearing Examples',
      slug: '/docs/market-structure/clearing-examples.mdx',
    },
  ],
};

export const userManual: LinkTreeMapping = {
  children: [
    { component: SidebarHeaderItem, heading: 'User Manual' },
    { heading: 'User Manual', slug: '/docs/user-manual.mdx' },
  ],
};

export const allDocs: LinkTreeMapping = {
  component: SidebarFragment,
  heading: 'OneChronos Docs',
  children: [
    ...(expressiveBidding.children || []),
    ...(marketStructure.children || []),
    ...(fixIntegration.children || []),
    ...(userManual.children || []),
  ],
};
